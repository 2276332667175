// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    openMenu: false
};

// ==============================|| SLICE - MENU ||============================== //

const coursemenu = createSlice({
    name: 'coursemenu',
    initialState,
    reducers: {
        handlerOpenMenu(state, action) {
            state.openMenu = action.payload.openMenu;
        }
    }
});

export default coursemenu.reducer;

export const { handlerOpenMenu } = coursemenu.actions;
