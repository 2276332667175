// material-ui
import { Box, IconButton, Link, useMediaQuery } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
// project import
import Search from './Search';
import Profile from './Profile';
// import Notification from './Notification';
import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const handleLogout = () => {
        // Clear localStorage
        localStorage.clear();

        // Remove cache data from localStorage
        const cacheKeys = Object.keys(localStorage).filter((key) => key.startsWith('cache-'));
        cacheKeys.forEach((key) => {
            localStorage.removeItem(key);
        });

        // Navigate to the desired location
        // When user logs out
        localStorage.removeItem('isAuthenticated');

        navigate('/', { state: { name: 'AuthLogin' } });
    };

    return (
        <>
            {!matchesXs && <Search />}
            {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

            <IconButton onClick={handleLogout} disableRipple color="secondary" sx={{ color: 'text.primary', bgcolor: 'grey.100' }}>
                <LogoutIcon />
            </IconButton>

            {/* <Notification /> */}
            {!matchesXs && <Profile />}
            {/* {matchesXs && <MobileSection />} */}
        </>
    );
};

export default HeaderContent;
