// assets
import { BgColorsOutlined } from '@ant-design/icons';
// icons
const icons = {
    BgColorsOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const foundray = {
    id: 'foundry',
    type: 'group',
    children: [
        {
            id: 'foundry',
            title: 'Foundry',
            type: 'item',
            url: '/foundry',
            icon: icons.BgColorsOutlined,
            breadcrumbs: false
        }
    ]
};

export default foundray;
