// project import
import NavCard from './NavCard';
import { useSelector } from 'react-redux';
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
    const { openMenu } = useSelector((state) => state.coursemenu);
    console.log('Open Menu Bar', openMenu);
    return (
        <SimpleBar
            sx={{
                '& .simplebar-content': {
                    display: 'flex',
                    flexDirection: 'column'
                }
            }}
        >
            {openMenu ? <Navigation /> : <Navigation />}

            {/* <NavCard /> */}
        </SimpleBar>
    );
};

export default DrawerContent;
