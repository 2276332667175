// assets
import { HomeOutlined } from '@ant-design/icons';
// icons
const icons = {
    HomeOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const Inventory = {
    id: 'foundry',
    type: 'group',
    children: [
        {
            id: 'foundry',
            title: 'Inventory',
            type: 'item',
            url: '/inventory',
            icon: icons.HomeOutlined,
            breadcrumbs: false
        }
    ]
};

export default Inventory;
