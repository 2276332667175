import { LOGIN, LOGIN_INITIATED, LOGIN_SUCCESS, LOGIN_FAILED } from '../actions';
import { takeLatest, call, put } from 'redux-saga/effects';
import { loginUser } from '../../services/AuthService';
// import { loginAdmin } from '../../services/AuthAdminService';

// import { useNavigate } from 'react-router-dom';
import { push } from 'connected-react-router';

// const navigate = useNavigate();

export function* loginSaga() {
    yield takeLatest(LOGIN, workerSaga);
}

function* workerSaga(action) {
    console.log('login action', action);
    // console.log('login response', response);
    try {
        yield put({ type: LOGIN_INITIATED });
        if (action.payload.data.roll === 'admin') {
            const response = yield call(loginUser, action.payload.data);
            if (response) {
                yield put({ type: LOGIN_SUCCESS, payload: response.data });
                yield call(saveInLocalStorage, response.data);
                console.log('login response', response);
            } else {
                yield put({ type: LOGIN_FAILED, payload: response.data.message });
            }
        } else {
            const response = yield call(loginUser, action.payload.data);
            if (response) {
                yield put({ type: LOGIN_SUCCESS, payload: response.data });
                yield call(saveInLocalStorage, response.data);
            } else {
                yield put({ type: LOGIN_FAILED, payload: response.data.message });
            }
        }
    } catch (error) {
        yield put({
            type: LOGIN_FAILED,
            payload: 'The emailId or password you entered is incorrect. Please try again.'
        });
        console.log('login err', error);
    }
}

const saveInLocalStorage = (response) => {
    let loginDetails = response;
    console.log('====================================');
    console.log('respones::', response);
    console.log('====================================');
    // localStorage.setItem('token', loginDetails.token);
    localStorage.setItem('token', loginDetails);
    // localStorage.setItem('roll', 'admin');
};
