import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
// const Userdashboardcard = Loadable(lazy(() => import('pages/User-Profile/index')));
// const Certificates = Loadable(lazy(() => import('pages/Certificate/Certificate')));
const Addcustomer = Loadable(lazy(() => import('pages/Customer/Addcustomer.js')));
const Addproduct = Loadable(lazy(() => import('pages/Product/Addproduct.js')));
const Producttable = Loadable(lazy(() => import('pages/Product/Producttable')));
const Orders = Loadable(lazy(() => import('pages/Orders/Orders')));
const OrderTable = Loadable(lazy(() => import('pages/Orders/Ordertable')));

const CustomerTable = Loadable(lazy(() => import('pages/Customer/Customertable')));
const Report = Loadable(lazy(() => import('pages/Reports/report')));
const OrderReport = Loadable(lazy(() => import('pages/Reports/Orderreport')));
const ProductionReport = Loadable(lazy(() => import('pages/Reports/MoulderdateInput')));
const MarketingReport = Loadable(lazy(() => import('pages/Reports/MoulderwiseInput')));
const Users = Loadable(lazy(() => import('pages/Barcode/user')));
const Usertable = Loadable(lazy(() => import('pages/User-Profile/UserTable')));
const AddUser = Loadable(lazy(() => import('pages/User-Profile/AddUser')));
const Packing = Loadable(lazy(() => import('pages/Packing-Slip/Packing')));
const GetOrderReport = Loadable(lazy(() => import('pages/Reports/PrintOrder')));
const GetMoulderReport = Loadable(lazy(() => import('pages/Reports/PrintMoulder')));
const GetMoulder = Loadable(lazy(() => import('pages/Moulder/Getmoulder')));
const AddMoulder = Loadable(lazy(() => import('pages/Moulder/AddMoulder')));
const AddMoulderDeatils = Loadable(lazy(() => import('pages/Moulder/Createmoulderdetails')));
const AddSand = Loadable(lazy(() => import('pages/Sand/Addsanddata')));
const Sandtest = Loadable(lazy(() => import('pages/Sand/Sandtest')));
const AddPouring = Loadable(lazy(() => import('pages/Foundry/Addpouring')));
const Pouringtest = Loadable(lazy(() => import('pages/Foundry/Pouring')));
const AddCasting = Loadable(lazy(() => import('pages/Foundry/Addcasting')));
const Castingtest = Loadable(lazy(() => import('pages/Foundry/Casting')));
const Foundry = Loadable(lazy(() => import('pages/Foundry/Foundry')));
const SandTestReport = Loadable(lazy(() => import('pages/Reports/Sandtestreport')));
const PrintSandTestReport = Loadable(lazy(() => import('pages/Reports/Printsand')));
const PouringTestReport = Loadable(lazy(() => import('pages/Reports/Pouring')));
const PrintPouringReport = Loadable(lazy(() => import('pages/Reports/Printpouring')));
const Rejectionanalysis = Loadable(lazy(() => import('pages/Reports/Rejectionanalysis')));
const Printrejectionalaysis = Loadable(lazy(() => import('pages/Reports/Printrejectionalaysis')));
const Printmoulderwise = Loadable(lazy(() => import('pages/Reports/PrintMoulderwise')));
const Printinventory = Loadable(lazy(() => import('pages/Reports/Printinventory')));
const Printcasting = Loadable(lazy(() => import('pages/Reports/Printcastingreport')));
const Castingdateinput = Loadable(lazy(() => import('pages/Reports/CastingdateInput')));
const AddInventory = Loadable(lazy(() => import('pages/Inventory/Addinventory')));
const Inventory = Loadable(lazy(() => import('pages/Inventory/Inventory')));
const AddInventoryReportDate = Loadable(lazy(() => import('pages/Reports/Inventoryinput')));
const Role = Loadable(lazy(() => import('pages/ROLE/Role')));
const Addrole = Loadable(lazy(() => import('pages/ROLE/Addrole')));
const GetMoulderDetails = Loadable(lazy(() => import('pages/Moulder/Getmoulderdetails')));
// render - utilities

// ==============================|| MAIN ROUTING ||============================== //

const UserRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'dashboard',
            element: <DashboardDefault />
        },

        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'adduser',
            element: <AddUser />
        },
        {
            path: 'updateuser',
            element: <AddUser />
        },

        {
            path: 'user-table',
            element: <Usertable />
        },

        {
            path: 'addcustomer',
            element: <Addcustomer />
        },
        {
            path: 'updatecostumer',
            element: <Addcustomer />
        },

        {
            path: 'addproduct',
            element: <Addproduct />
        },
        {
            path: 'updateproduct',
            element: <Addproduct />
        },

        {
            path: 'product_table',
            element: <Producttable />
        },

        {
            path: 'addorders',
            element: <Orders />
        },
        {
            path: 'update-orders',
            element: <Orders />
        },

        {
            path: 'order-table',
            element: <OrderTable />
        },

        {
            path: 'customertable',
            element: <CustomerTable />
        },
        {
            path: 'reports',
            element: <Report />
        },
        {
            path: 'user',
            element: <Users />
        },
        {
            path: 'barcode',
            element: <Users />
        },
        {
            path: 'order-report',
            element: <OrderReport />
        },
        {
            path: 'production-report',
            element: <ProductionReport />
        },
        {
            path: 'moulderwise-report',
            element: <MarketingReport />
        },

        {
            path: 'packing-slip',
            element: <Packing />
        },
        {
            path: 'get-order-report',
            element: <GetOrderReport />
        },
        {
            path: 'get-moulder-report',
            element: <GetMoulderReport />
        },

        {
            path: 'moulder',
            element: <GetMoulder />
        },
        {
            path: 'add-moulder',
            element: <AddMoulder />
        },
        {
            path: 'update-moulder',
            element: <AddMoulder />
        },
        {
            path: 'add-sand',
            element: <AddSand />
        },
        {
            path: 'update-sand',
            element: <AddSand />
        },
        {
            path: 'sand-test',
            element: <Sandtest />
        },
        {
            path: 'add-pouring',
            element: <AddPouring />
        },
        {
            path: 'update-pouring',
            element: <AddPouring />
        },
        {
            path: 'pouring',
            element: <Pouringtest />
        },
        {
            path: 'add-casting',
            element: <AddCasting />
        },
        {
            path: 'update-casting',
            element: <AddCasting />
        },
        {
            path: 'casting',
            element: <Castingtest />
        },

        {
            path: 'foundry',
            element: <Foundry />
        },
        {
            path: 'sand-test-report',
            element: <SandTestReport />
        },
        {
            path: 'print-sand-test-report',
            element: <PrintSandTestReport />
        },
        {
            path: 'pouring-report',
            element: <PouringTestReport />
        },
        {
            path: 'print-pouring-report',
            element: <PrintPouringReport />
        },
        {
            path: 'rejection-analysis-report',
            element: <Rejectionanalysis />
        },
        {
            path: 'print-rejection-analysis',
            element: <Printrejectionalaysis />
        },
        {
            path: 'create-moulder',
            element: <AddMoulderDeatils />
        },
        {
            path: 'update-moulder',
            element: <AddMoulderDeatils />
        },
        {
            path: 'print-moulderwise',
            element: <Printmoulderwise />
        },
        {
            path: 'casting-report',
            element: <Castingdateinput />
        },
        {
            path: 'print-casting',
            element: <Printcasting />
        },
        {
            path: 'print-inventory',
            element: <Printinventory />
        },
        {
            path: 'inventory',
            element: <Inventory />
        },
        {
            path: 'add-inventory',
            element: <AddInventory />
        },
        {
            path: 'update-inventory',
            element: <AddInventory />
        },
        {
            path: 'inventory-report',
            element: <AddInventoryReportDate />
        },

        {
            path: 'role',
            element: <Role />
        },
        {
            path: 'add-role',
            element: <Addrole />
        },
        {
            path: 'update-role',
            element: <Addrole />
        },
        {
            path: 'get-moulder-details',
            element: <GetMoulderDetails />
        }
    ]
};

export default UserRoutes;
