// project import
import userauth from './userauth';
import admindashboard from './Admindashboard';
// import utilities from './utilities';
import userprofile from './userprofile';
import Product from './support';
import userdashboard from './userdashboard';
import Certificates from './Certificates';
import Customer from './Announcement';
import Orders from './Contact';
import adminpages from './admin';
import Role from './Role';
import foundray from './foundray';
import report from './reports';
import SandTest from './SandTest';
import Adminannouncements from './Adminannouncements';
import evaluate from './Moulder';
import Inventory from './Inventory';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [userdashboard, Orders, Role, report, Customer, Product, evaluate, SandTest, foundray, Inventory, userprofile] // For user menu
};

export default menuItems;

// Project import
// import userauth from './userauth';
// import admindashboard from './Admindashboard';
// // Import utilities from './utilities';
// import userprofile from './userprofile';
// import Product from './support';
// import userdashboard from './userdashboard';
// import Certificates from './Certificates';
// import Customer from './Announcement';
// import Orders from './Contact';
// import adminpages from './admin';
// import Role from './Role';
// import foundray from './foundray';
// import report from './reports';
// import SandTest from './SandTest';
// import Moulder from './Moulder';
// import Inventory from './Inventory';

// // ==============================|| MENU ITEMS ||============================== //
// let Roles = localStorage.getItem('Role');
// let menuItems;
// console.log('Roles:', Roles);

// if (Roles === 'Admin' || Roles === 'Assit_Manager' || Roles === 'Owner') {
//     menuItems = {
//         items: [userdashboard, Orders, Role, report, Customer, Product, Moulder, SandTest, foundray, Inventory, userprofile] // For user menu
//     };
// } else if (Roles === 'Inventory') {
//     menuItems = {
//         items: [userdashboard, report, Inventory, userprofile] // For inventory menu
//     };
// } else if (Roles === 'User') {
//     menuItems = {
//         items: [userdashboard, report, Inventory, foundray, userprofile] // For user menu
//     };
// } else if (Roles === 'Foundry') {
//     menuItems = {
//         items: [userdashboard, report, Moulder, SandTest, foundray, userprofile] // For foundry menu
//     };
// }

// console.log('menuItems', menuItems); // Log the value of menuItems
// console.log(menuItems && menuItems.items);

// export default menuItems;
