import { UsergroupAddOutlined } from '@ant-design/icons';
// icons
const icons = {
    UsergroupAddOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const Announcements = {
    id: 'Customers',
    type: 'group',
    children: [
        {
            id: 'announcements',
            title: 'Customers',
            type: 'item',
            url: '/customertable',
            icon: icons.UsergroupAddOutlined,
            breadcrumbs: false
        }
    ]
};

export default Announcements;
