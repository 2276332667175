// assets
import { ChromeOutlined, QuestionOutlined, UserOutlined } from '@ant-design/icons';

// icons
const icons = {
    ChromeOutlined,
    QuestionOutlined,
    UserOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const userprofile = {
    id: 'userprofile',
    type: 'group',
    children: [
        {
            id: 'user-table',
            title: 'User',
            type: 'item',
            url: '/user-table',
            icon: icons.UserOutlined
        }
    ]
};

export default userprofile;
