import axios from 'axios';

export const post = (url, data = {}) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        const options = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                authorization: token
            }
        };
        // debugger;
        return (
            axios
                .post(url, data, { ...options })
                // .then((response) => resolve(response))
                .then((response) => resolve(response, console.log(response)))
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data.error);
                    } else {
                        reject('');
                    }
                })
        );
    });
export const postImage = (url, data = {}) =>
    new Promise((resolve, reject) => {
        // const token = localStorage.getItem('token');
        const token = localStorage.getItem('token');
        const options = {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: token
            }
        };
        // debugger;
        return (
            axios
                .post(url, data, { ...options })
                // .then((response) => resolve(response))
                .then((response) => resolve(response, console.log(response)))
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data.error);
                    } else {
                        reject('');
                    }
                })
        );
    });

export const get = (url, params) =>
    new Promise((resolve, reject) => {
        // let loginDetails = localStorage.getItem('')
        // const token = localStorage.getItem('token');
        const token = localStorage.getItem('token');
        const options = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                authorization: token
            }
        };
        axios
            .get(url, { ...options }, { params })
            .then((response) => resolve(response, console.log(response)))
            .catch((error) => {
                reject(error);
            });
    });

export const getpdf = (url, params) =>
    new Promise((resolve, reject) => {
        // const token = localStorage.getItem('token');
        const token = localStorage.getItem('token');
        const options = {
            headers: {
                'Content-Type': 'application/pdf',

                authorization: token
            }
        };
        axios
            .get(url, { ...options }, { params })
            .then((response) => resolve(response, console.log(response)))
            .catch((error) => {
                reject(error);
            });
    });

export const put = (url, data = {}) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        const options = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                authorization: token
            }
        };
        return axios
            .put(url, data, { ...options })
            .then((response) => resolve(response))
            .catch((error) => {
                reject(error);
            });
    });

export const remove = (url = {}) =>
    new Promise((resolve, reject) => {
        // ;
        const token = localStorage.getItem('token');
        const options = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                authorization: token
            }
        };
        return axios
            .delete(url, { ...options })
            .then((response) => resolve(response))
            .catch((error) => {
                reject(error);
            });
    });

export const postFile = (url, data = {}) =>
    new Promise((resolve, reject) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const options = {
            headers: {
                // "Content-Type": "application/json;charset=UTF-8",
                'Content-Type': 'multipart/form-data',
                'x-access-token': token
            }
        };
        // debugger;
        return axios
            .post(url, data, { ...options })
            .then((response) => resolve(response))
            .catch((error) => {
                if (error.response) {
                    reject(error.response.data.error);
                } else {
                    reject('');
                }
            });
    });
