import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const AuthForgetpassword = Loadable(lazy(() => import('pages/authentication/Forgetpassword')));
const AuthResetpassword = Loadable(lazy(() => import('pages/authentication/Resetpassword.js')));
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: 'register',
            element: <AuthRegister />
        },
        {
            path: 'forget',
            element: <AuthForgetpassword />
        },
        {
            path: 'reset-password',
            element: <AuthResetpassword />
        }
    ]
};

export default LoginRoutes;
