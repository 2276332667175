// assets
import { MonitorOutlined } from '@ant-design/icons';
// icons
const icons = {
    MonitorOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const SandTest = {
    id: 'sand-test',
    type: 'group',
    children: [
        {
            id: 'sandtest',
            title: 'Sand Test',
            type: 'item',
            url: '/sand-test',
            icon: icons.MonitorOutlined,
            breadcrumbs: false
        }
    ]
};

export default SandTest;
