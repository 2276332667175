// assets
import { ReconciliationOutlined } from '@ant-design/icons';
// icons
const icons = {
    ReconciliationOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const report = {
    id: 'report',
    type: 'group',
    children: [
        {
            id: 'report',
            title: 'Reports',
            type: 'item',
            url: '/reports',
            icon: icons.ReconciliationOutlined,
            breadcrumbs: false
        }
    ]
};

export default report;
