// import { useRoutes } from 'react-router-dom';
// import LoginRoutes from './LoginRoutes';
// import UserRoutes from './UserRoutes';

// // ==============================|| ROUTING RENDER ||============================== //

// export default function ThemeRoutes() {
//     // return useRoutes([AdminRoutes, Admindashboard, DatatableRoutes, CoursedetailsRoutes]);
//     // AdminRoutes;

//     //Note : Prashant uncomment if you are build for User client

//     return useRoutes([LoginRoutes, UserRoutes]);
//     LoginRoutes;
// }

import React, { useEffect, useState } from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import LoginRoutes from './LoginRoutes';
import UserRoutes from './UserRoutes';

// Check if the user is authenticated based on the flag in local storage
const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

export default function ThemeRoutes() {
    const navigate = useNavigate();
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        if (initialLoad) {
            setInitialLoad(false);
            if (isAuthenticated) {
                navigate('/dashboard', { replace: true }); // Redirect to dashboard if authenticated
            }
        }
    }, [initialLoad, navigate]);

    return useRoutes([LoginRoutes, UserRoutes]);
}
