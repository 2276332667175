import { LOGIN_INITIATED, LOGIN_SUCCESS, LOGIN_FAILED } from '../actions';

const initialState = {
    error: false,
    success: false,
    isLoggedIn: false,
    message: ''
};

export function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_INITIATED:
            return {
                ...state,
                error: false,
                success: false,
                message: ''
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                error: false,
                success: true,
                isLoggedIn: true,
                message: ''
            };

        case LOGIN_FAILED:
            return {
                ...state,
                error: true,
                success: false,
                isLoggedIn: false,
                message: 'The emailId or password you entered is incorrect. Please try again.'
            };

        default:
            return state;
    }
}
