// assets
import { ShoppingCartOutlined } from '@ant-design/icons';
// icons
const icons = {
    ShoppingCartOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const support = {
    id: 'Products',
    type: 'group',
    children: [
        {
            id: 'Products',
            title: 'Products',
            type: 'item',
            url: '/product_table',
            icon: icons.ShoppingCartOutlined,
            breadcrumbs: false
        }
    ]
};

export default support;
