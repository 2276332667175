// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //
// Add  a filter for fetching the objects that are inside the items array
// Add a context hook and place on the top of the react tree
console.log(menuItem);
const Navigation = () => {
    let rollcheck = localStorage.getItem('role');
    console.log('role:::>', rollcheck);
    if (rollcheck === 'admin') {
        var navGroups = menuItem.items1.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="#167e77" align="center">
                            Fix - Navigation Group
                        </Typography>
                    );
            }
        });
    } else {
        var navGroups = menuItem.items.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="#167e77" align="center">
                            Fix - Navigation Group
                        </Typography>
                    );
            }
        });
    }

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
