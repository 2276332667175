import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { EditOutlined, ProfileOutlined, LogoutOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleListItemClick = (event, index) => {
        navigate('/dashboard', { state: { name: 'Userdashboardcard' } });
    };
    const handleLogout = () => {
        localStorage.clear();
        navigate('/', { state: { name: 'LoginPage' } });
    };
    const userrole = localStorage.getItem('role', 'role');
    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
            {userrole === 'admin' ? (
                <>
                    {' '}
                    <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
                        <ListItemIcon>
                            <LogoutOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                </>
            ) : (
                <>
                    {' '}
                    <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
                        <ListItemIcon>
                            <UserOutlined />
                        </ListItemIcon>
                        <ListItemText primary="View Profile" />
                    </ListItemButton>
                    <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
                        <ListItemIcon>
                            <LogoutOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                </>
            )}
        </List>
    );
};

// ProfileTab.propTypes = {
//     handleLogout: PropTypes.func
// };

export default ProfileTab;
