import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { getUser } from 'services/AuthService';
import { useNavigate } from 'react-router-dom';
import { MEDIA_URL } from 'helpers/urlConstant';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Grid,
    IconButton,
    Paper,
    Popper,
    Stack,
    Tab,
    Tabs,
    Typography
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import ProfileTab from './ProfileTab';

// assets
import avatar1 from 'assets/images/icons/avatar.png';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `profile-tab-${index}`,
        'aria-controls': `profile-tabpanel-${index}`
    };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    const handleLogout = () => {
        // Clear localStorage
        localStorage.clear();

        // Remove cache data from localStorage
        const cacheKeys = Object.keys(localStorage).filter((key) => key.startsWith('cache-'));
        cacheKeys.forEach((key) => {
            localStorage.removeItem(key);
        });

        // Navigate to the desired location
        // When user logs out
        localStorage.removeItem('isAuthenticated');

        navigate('/', { state: { name: 'LoginPage' } });
    };

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const iconBackColorOpen = 'grey.300';
    let Adminresult = '';
    const initialCourseState = {
        isLoading: false,
        listLevels: []
    };
    const [{ isLoading, listLevels }, setLevels] = useState(initialCourseState);

    const getUsers = async () => {
        setLevels((prevState) => ({ ...prevState, isLoading: true }));
        // APi callback
        let userId = localStorage.getItem('userId', 'userId');
        const response = await getUser(userId);
        console.log('user response', response.data[0]);
        let levels = response.data[0];
        console.log('Levels users', levels);
        if (response?.status === 200) {
            // api response levels
            setLevels((prevState) => ({ ...prevState, isLoading: false, listLevels: levels }));
            return;
        }
        console.log('listLevels', listLevels);
        setLevels((prevState) => ({ ...prevState, isLoading: false }));
    };
    useEffect(() => {
        getUsers();
    }, []);

    const role = localStorage.getItem('role', 'role');
    if (role === 'admin') {
        const admindata = localStorage.getItem('Admindata', 'Admindata');
        Adminresult = JSON.parse(admindata);
        console.log('admin result', Adminresult);
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: open ? iconBackColorOpen : 'transparent',
                    borderRadius: 1,
                    '&:hover': { bgcolor: 'secondary.lighter' }
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                    <Avatar alt="profile user" src={`${MEDIA_URL}${listLevels?.Profile}`} sx={{ width: 32, height: 32 }} />
                    <Typography variant="subtitle1">{listLevels?.Ename}&nbsp;</Typography>
                </Stack>
            </ButtonBase>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                sx={{
                                    boxShadow: theme.customShadows.z1,
                                    width: 290,
                                    minWidth: 240,
                                    maxWidth: 290,
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 250
                                    }
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard elevation={0} border={false} content={false}>
                                        <CardContent sx={{ px: 2.5, pt: 3 }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Stack direction="row" spacing={1.25} alignItems="center">
                                                        <Avatar
                                                            alt="profile user"
                                                            src={`${MEDIA_URL}${listLevels?.profilePath}`}
                                                            sx={{ width: 32, height: 32 }}
                                                        />
                                                        <Stack>
                                                            <Typography variant="h6">{listLevels?.Ename} &nbsp;</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        {open && (
                                            <>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <Tabs
                                                        variant="fullWidth"
                                                        value={value}
                                                        onChange={handleChange}
                                                        aria-label="profile tabs"
                                                    >
                                                        <Tab
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                textTransform: 'capitalize'
                                                            }}
                                                            icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                                                            label="Profile"
                                                            {...a11yProps(0)}
                                                        />
                                                    </Tabs>
                                                </Box>
                                                <TabPanel value={value} index={0} dir={theme.direction}>
                                                    <ProfileTab handleLogout={handleLogout} />
                                                </TabPanel>
                                            </>
                                        )}
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Profile;
